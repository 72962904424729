<template>
  <button
    :type="type"
    class="relative disabled:cursor-not-allowed hj:text-f-white hj:text-2xl hj:font-druk-bold hover:hj:bg-f-primary"
    :class="{ loading: loading, 'opacity-20': disabled }"
    :disabled="loading || disabled"
    @click="$emit('buttonClick')">
    <div class="absolute absolute-center-y -left-[15px]">
      <LoadingSpinner v-if="loading" size="x-small" />
    </div>
    {{ buttonText }}
  </button>
</template>

<script>
import LoadingSpinner from "./LoadingSpinner.vue"
export default {
  name: "LoadingButton",
  components: {
    LoadingSpinner,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
    text: {
      type: String,
    },
    loadingText: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
  },
  emits: ["buttonClick"],
  computed: {
    buttonText() {
      return this.loading ? this.loadingText : this.text
    },
  },
}
</script>

<style scoped>
button.loading {
  padding-left: 50px;
}
</style>
